import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import TrackShipment from "./Tracking";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import HomeNav from "./HomeNav";
import { format } from "date-fns";
import { Helmet } from "react-helmet";
import { Table } from "react-bootstrap";
import axios from "axios";

const TrackingDetailsPage = () => {
  const bg_pattern = {
    backgroundImage: ` url("/image/tracking-bg.png")`,
    backgroundSize: "cover",

    backgroundRepeat: "no-repeat",
  };
  const tracking_nav = {
    Position: "relative",
  };
  const [count, setCount] = useState(0);
  const { trackingNo } = useParams();
  const [trackingData, setTrackingData] = useState([]); 
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const splitTracking = trackingNo.split(/[\s,]+/);
  // console.log("splitnumber", trackingData);

  const fetchTrackingData = useCallback(async () => {
    // console.log("fetchTrackingData function recreated");

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_TRACKING_API_URL}/${splitTracking[count]}`
      );
      //  console.log("my url", response.data.data)
      if (response.data.status === true) {
        // setTrackingData([...trackingData, { ...response.data.data }]);
        setTrackingData ( prevData => [...prevData, { ...response.data.data }]);
        setLoading(false);
        setCount((prevCount) => prevCount + 1);
        setError(null);
      }
    } catch (error) {
      setError(
        `Tracking History is not available for this tracking no ${trackingNo} . Please try sometime `
      );
      setCount((prevCount) => prevCount + 1);

      setLoading(false);
    }
  }, [splitTracking, trackingNo, count]);

  useEffect(() => {
    // console.log("useEffect triggered");
    if (trackingNo.split(/[\s,]+/).length > count) {
      fetchTrackingData();
    }
  }, [fetchTrackingData, splitTracking, count]);

  // console.log("Tracking Data come", trackingData);

  if (loading) {
    return (
      <>
        <HomeNav position="absolute" topValue="inherit" />
        <div className="container mt-5">
          <Table bordered hover responsive>
            <thead>
              <tr>
                <th>LR No.</th>
                <th>Order ID</th>
                <th>Pickup Date</th>
                <th>Expected Date of Delivery</th>
                {/* <th>Invoice</th> */}
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {Array.from({ length: 4 }).map((_, index) => (
                <tr key={index}>
                  <td>
                    <Skeleton height={10} />
                  </td>
                  <td>
                    <Skeleton height={10} />
                  </td>
                  <td>
                    <Skeleton height={10} />
                  </td>
                  <td>
                    <Skeleton height={10} />
                  </td>
                  <td>
                    <Skeleton height={10} />
                  </td>
                  <td>
                    <Skeleton height={10} />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>Multiple Order Tracking</title>
      </Helmet>
      <HomeNav position="relative" />

      <div className="tracking-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-7 step-part">
              <div className="primum-steps">
              <h4 className="text-white">
                Shipments searched:({trackingNo.split(/[\s,]+/).length})
              </h4>
              </div>
            
            </div>
            <div className="col-lg-5">
              <TrackShipment />
            </div>
          </div>
        </div>
      </div>
      {/* {error ===
        `Tracking History is not available for this tracking no ${trackingNo} . Please try sometime ` && (
        <div className="text-center mt-5">
          <img
            src="/image/TrackingNumberNotFound.png"
            alt=""
            srcset=""
            className="pincodeNotFound"
          />
        </div>
      )} */}

      <div className="container-fluid">
        <Table striped bordered hover responsive className="mt-5">
          <thead>
            <tr>
              <th>Tracking&nbsp;No.</th>
              <th>Pickup&nbsp;Date</th>
              <th>Expected&nbsp;Date</th>
              <th>Last Scan Location</th>
              <th>Status</th>
              {/* <th></th> */}
            </tr>
          </thead>

          <tbody>
            {trackingData.map((data, id) => (
              <tr key={id}>
                <td>{data.tracking_no}</td>
                <td>{data.pickupDate} </td>
                <td>{data.promisedDate}</td>
                <td>{data?.scaninfo[0]?.location}</td>

                <td>
                  {" "}
                  <div
                    className={` status_scan ${
                      (data.status === "In Transit" ? "transit" : "") ||
                      (data.status === "Manifested" ? "manifested" : "") ||
                      (data.status === "Delivered" ? "delivered" : "") ||
                      (data.status === "Not Picked" ? "notpicked" : "") ||
                      (data.status === "Dispatched" ? "dispatched" : "") ||
                      (data.status === "Pending" ? "pending" : "") ||
                      (data.status === "RTO" ? "rto" : "")
                    } `}
                  >
                    {" "}
                    {data.status}
                  </div>{" "}
                </td>

                <td>
                  <Link to={`/tracking_details/${data?.tracking_no}`}>
                    <button className="btn btn-success button-3">
                      <i class="fa-solid fa-location-dot">&nbsp;</i>Track
                    </button>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default TrackingDetailsPage;
