import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Navigation from "../components/Navigation";
import { Link } from "react-router-dom";

const DashBoard = ({updateMetaTags}) => {
  useEffect(() => {
    updateMetaTags("Shipping and logistics companies in india | Dashboard Features | Truxcargo",
    "Streamline your shipping and logistics operations in India with Truxcargo's powerful dashboard features.",
    "Shipping and logistics companies in india, Logistics courier company in india");
  }, [updateMetaTags]);
  const contact = {
    background: `url("/image/dashboard/bg.png")`,
    width: "100%",
    // height: "100vh",
    backgroundSize: "100% 100%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    padding: "5% 0",

    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  };
  const trusted = {
    background: `url("/image/dashboard/signup.png")`,
    backgroundSize: "cover",
    backgroundPosition: "inherit",
    width: "100%",
    borderRadius: "30px",
    backgroundRepeat: "no-repeat",
  };
  return (
    <>
      <Helmet>
        <title>
          Shipping and logistics companies in india | Dashboard Features |
          Truxcargo
        </title>
    
      </Helmet>
      <Navigation />
      <section className="contact_section" style={contact}>
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-md-12 col-12">
              <div className="wrapper_dashborad ">
                <h2>One-Stop Operations Dashboard</h2>
                <p>
                  From Orders to Shipments, NDR to RTO, Master Your Business
                  Operations with Our Versatile Dashboard Solution.
                </p>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-12">
              <img
                src="/image/dashboard/1.png"
                alt=""
                srcset=""
                className="w-100"
              />
            </div>
          </div>
        </div>
      </section>

      <div className="container-fluid margin_top_bottom">
        <div className="ndr-mangement-section ">
          <div className="ndr-mangemnet-part ">
            <div className="row justify-content-center">
              <div className="col-lg-7 col-md-12 col-12">
                <h2>About our Dashboard</h2>

                <p>
                  Our dashboard is the backbone of efficient business
                  operations, serving as a comprehensive solution to manage
                  orders, shipments, NDR, RTO, and more, all within one
                  intuitive interface.
                </p>
                <p>
                  This dashboard is designed to be user-friendly and adaptable
                  to various industries and business sizes. It centralizes your
                  diverse operations, reducing complexity and improving
                  efficiency. With a commitment to simplifying the management of
                  your day-to-day tasks, our dashboard is the key to unlocking
                  greater productivity, customer satisfaction, and overall
                  business success.
                </p>
                <ul>
                  <li>
                    <b> Efficient Business Operations: </b>Manage orders,
                    shipments, NDR, RTO, and more with our comprehensive
                    dashboard. Stay organized and save time.
                  </li>
                  <li>
                    <b> Seamless Integration:</b> Truxcargo seamlessly
                    integrates with your ERP, CRM, or other logistics software
                    to ensure a smooth transition.
                  </li>
                  <li>
                    <b> All-in-One Solution: </b>Say goodbye to multiple
                    systems. Our intuitive interface consolidates all your
                    logistics needs in one place.
                  </li>
                </ul>
              </div>

              <div className="col-lg-5 col-md-7 col-12">
                <div className="text-end">
                  <img
                    src="/image/dashboard/2.png"
                    alt=""
                    srcset=""
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </div>
            <div className="all_rounder_gradient_box"></div>
          </div>
        </div>
      </div>

      <section className="features_dashboard margin_top_bottom">
        <div className="container-fluid ">
          <div className="row ">
            <div className="col-lg-10 col-md-12">
              <div className="">
                <h2 className="mb-3">Features of Dashboard</h2>
                <p>
                  Welcome to our Dashboard Features page, where you'll discover
                  the power of our intuitive platform. Analyze essential
                  business metrics effortlessly and enhance your performance.
                  With customizable widgets and real-time updates, our dashboard
                  empowers you to make informed decisions and drive your
                  business forward.
                </p>
                <ul>
                  <li>
                    <b>Rate Calculator:</b> Instantly calculate shipping costs
                    based on pin codes, weight, and locations for accurate
                    budgeting.
                  </li>
                  <li>
                    <b>Pickup Point Input:</b> Efficiently set pickup points or
                    pin codes, ensuring smooth deliveries from/to designated
                    locations.
                  </li>

                  <li>
                    <b>Wallet Recharge:</b> Quickly refill your prepaid wallet
                    for seamless, convenient, and swift payment options.
                  </li>
                  <li>
                    <b>Order Creation:</b> Easily generate orders with package
                    details, address, and contact information for streamlined
                    processing.
                  </li>
                  <li>
                    <b>Weight Dispute Resolution: </b>Effectively manage and
                    resolve courier-indicated weight discrepancies for accurate
                    and reliable shipments.
                  </li>
                  <li>
                    <b>Early COD Remittance:</b> Optimize cash flow with daily
                    or periodic COD remittances, providing flexibility and
                    financial control.
                  </li>
                  <li>
                    <b>Refer & Earn: </b> Increase earnings through referrals,
                    building a network for mutual benefits.
                  </li>
                  <li>
                    <b>Price List:</b> Transparent pricing for informed
                    decisions and effective cost planning.
                  </li>
                  <li>
                    <b>Integrations: </b>Seamless system integration for
                    enhanced operational efficiency and workflow cohesion.
                  </li>
                  <li>
                    <b>Resource Center:</b> Access valuable tools and
                    information for optimized platform utilization and
                    performance.
                  </li>
                </ul>
              </div>
            </div>
            
          </div>
        </div>
      </section>
      <div className="container-fluid margin_top_bottom">
        <div className="shiping_now" style={trusted}>
          <div className="row justify-content-center">
            <div className="col-md-8 col-11 ">
              <div className="shiping_now_inside">
                <h1>Take the first step towards growth. </h1>
                <p>Sign up with us now</p>

                <Link to="https://b2b.truxcargo.com/register/">
                  <button className="btn btn-light ">Register</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashBoard;
